import React from 'react'
import xildhibaan from '../Assets/xildhibaan.jpeg'
import khadar from '../Assets/khadar.jpeg'
import gudoomiyaha from "../Assets/abdishakuur.jpg";
import omar from '../Assets/omar.jpeg'
import xarbi from '../Assets/xarbi.jpeg'


function Testimonial() {
    
  return (
    <div>
       < div class="container my-24 px-6 mx-auto">


<section class="mb-32 text-gray-800 text-center">

  <h2 class="text-3xl font-bold mb-12">Testimonials</h2>

  <div id="carouselExampleCaptions" class="carousel slide relative carousel-dark" data-bs-ride="carousel">
    <div class="carousel-inner relative w-full overflow-hidden">
      <div class="carousel-item active relative float-left w-full">
        <img class="rounded-full shadow-lg mb-6 mx-auto"
          src={xildhibaan} alt="avatar" style={{width: 150}} />
        <div class="flex flex-wrap justify-center">
          <div class="grow-0 shrink-0 basis-auto w-full lg:w-8/12 px-3">
            <h5 class="text-lg font-bold mb-3">Xildhibaan Jamal Ali Adan (jaah)</h5>
            <p class="font-medium text-gray-700 mb-4">Xildhibaan Golaha wakiilada ah</p>
            <p class="text-gray-500 mb-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-left"
                class="w-6 pr-2 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z">
                </path>
              </svg>in bulsho loo shaqeeyo, daryeelkeeda iyo caafimaadkeeda loo shaqeeyaa waa waxyaabaha maanta Somaliland ugu baahida badantahay”
            </p>
        
          </div>
        </div>
      </div>
  
      <div class="carousel-item relative float-left w-full">
        <img class="rounded-full shadow-lg mb-6 mx-auto bg-red-300"
          src={gudoomiyaha} alt="avatar" style={{width: 160 , height:140}} />
        <div class="flex flex-wrap justify-center">
          <div class="grow-0 shrink-0 basis-auto w-full lg:w-8/12 px-3">
            <h5 class="text-lg font-bold mb-3"> Abdishakuur ibrahim abdi</h5>
            <p class="font-medium text-gray-700 mb-4">Gudoomiyaha Shirkada badbaado</p>
            <p class="text-gray-500 mb-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-left"
                class="w-6 pr-2 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z">
                </path>
              </svg>
              Waxa aanu keenay Gaadiid casri ah, oo la jaanqaadaya nidaamka gurmadka degdega ah
            </p>
          
          </div>
        </div>
      </div>
      <div class="carousel-item relative float-left w-full">
        <img class="rounded-full shadow-lg mb-6 mx-auto"
          src={khadar} alt="avatar" style={{width: 150}} />
        <div class="flex flex-wrap justify-center">
          <div class="grow-0 shrink-0 basis-auto w-full lg:w-8/12 px-3">
            <h5 class="text-lg font-bold mb-3"> Khadar Jilaal Jama</h5>
            <p class="font-medium text-gray-700 mb-4">Gudoomiye ku xigeenka xidhiidhka kubada cagta somaliland</p>
            <p class="text-gray-500 mb-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-left"
                class="w-6 pr-2 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z">
                </path>
              </svg>
              Waan hambalyeynayaa shirkadda badbaado taas oo ah shirkaddii ugu hureysey ee gaar loo leeyahay ee laga furo somaliland
            </p>
          
          </div>
        </div>
      </div>
      <div class="carousel-item relative float-left w-full">
        <img class="rounded-full shadow-lg mb-6 mx-auto"
          src={omar} alt="avatar" style={{width: 150, height:140}} />
        <div class="flex flex-wrap justify-center">
          <div class="grow-0 shrink-0 basis-auto w-full lg:w-8/12 px-3">
            <h5 class="text-lg font-bold mb-3">Omar Ahmed Aadan</h5>
            <p class="font-medium text-gray-700 mb-4">Gudoomiyaha Degmada 26 June,Hargeysa </p>
            <p class="text-gray-500 mb-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-left"
                class="w-6 pr-2 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z">
                </path>
              </svg>Muhiimadu waxa weeye gaadhigan ambulance ka ah wuxuu leeyahay wixii loogu talagalay oo dhan
            </p>
          
          </div>
        </div>
      </div>
      <div class="carousel-item relative float-left w-full">
        <img class="rounded-full shadow-lg mb-6 mx-auto"
          src={xarbi} alt="avatar" style={{width: 150}} />
        <div class="flex flex-wrap justify-center">
          <div class="grow-0 shrink-0 basis-auto w-full lg:w-8/12 px-3">
            <h5 class="text-lg font-bold mb-3">Abdishakour Harbi </h5>
            <p class="font-medium text-gray-700 mb-4">dhiirigeliye iyo xirfad yahan</p>
            <p class="text-gray-500 mb-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-left"
                class="w-6 pr-2 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z">
                </path>
              </svg>run ahaantii badbaado waxa ay xalisey mushkilad jirtey
            </p>
          
          </div>
        </div>
      </div>
    </div>
    
    <button
      class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
      type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
      type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

</section>


</div>
    </div>
  )
}

export default Testimonial
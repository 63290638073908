import Second from "../Assets/six.jpg";
import Third from "../Assets/Gallery/pic1.jpg";
import four from "../Assets/nine.jpg";

export default [
  {
    title: "Badbaado Ambulance",
    description: "waxaad ka helaysa adeega Gurmadka Degdega Ah (First Aid)",
    urls: Second,
  },
  {
    title: "Badbaado Ambulance",
    description: "Waxaad ka helaysaa Qiime ku raali galiya iyo adeeg cafimaad",
    urls: Third,
  },
  {
    title: "Badbaado Ambulance",
    description:
      "Waxaad kale oo ka helaysaa adeegyada cafimaadka oo dhamays tiran",
    urls: four,
  }
];

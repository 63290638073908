import React from 'react'
import GaleryHeader from './GalleryHeader'
// import gallery from './gallery'
import galleryImage from './galleryImage'


function Gallery() {
  return (
    <>
<GaleryHeader/>
<h1 class="text-center font-bold py-10 text-3xl dark:text-white">Image Gallery </h1>
<div className="container">
<div class="grid grid-cols-2 md:grid-cols-3 gap-4 md:mx-20 container">
  {galleryImage.map((gallery)=>
  <div className=''>
  <img src={gallery.url} class="w-96   rounded-md hover:bg-red-100 hover:opacity-70 hov" alt="..." />
  </div>
  
  )}
  
</div></div>
<div className="mb-10"></div>
    </>
  )
}

export default Gallery
import React, { useRef,useState } from 'react';
import callcenter from "../Assets/callcenter2.svg";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
function ContactUs() {
	const form = useRef();
	const [send, setsend] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5h1uywn', 'template_ysa2wli', form.current, '-ERPHAqR5SKNQN_66')
      .then((result) => {
          console.log(result.text);
          setsend(true);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div>
      <div class="text-center mb-8 px-6 mt-12">
        <h1 class="font-display font-bold text-5xl mb-6">Contact Us</h1>
        <p class="max-w-lg mx-auto">
          BAD-BAADO Ambulance is the only Private ambulance service in
          Somaliland. We are going provide pre-hospital care services
          consistently and in line with international healthcare standards. We
          have in place a team of highly qualified pre-hospital care specialists
          with the ability of delivering best services possible
        </p>
      </div>

      <div class="container mx-auto px-6 mb-12 ">
        <div class="grid grid-cols-1 lg:grid-cols-2  gap-12 max-w-4xl mx-auto">
          <div className="space-y-8">
            <Link
              to=""
              class="flex items-center my-2 mt-20"
            >
              <svg
                class="w-4 h-4 mr-2 "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                ></path>
              </svg>
              634273714 | 659991331
            </Link>

            <Link
              to=""
              class="flex items-center my-2"
            >
              <svg
                class="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                ></path>
              </svg>
              info@badbaadoambulance.com
            </Link>

            <div class="flex items-center my-2">
              <svg
                class="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              Open 24/7
            </div>

            <a href=''
              title=""
              class="block flex items-center opacity-75 hover:opacity-100"
            >
              <span className="text-white">
                <img src={callcenter} className="text-white" alt="" />
              </span>
            
              <a href="tel:3033"class="ml-3 font-semibold">3033</a>
            </a>

            <Link
              to=""
              class="flex items-center my-2"
            >
              <svg
                class="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                ></path>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                ></path>
              </svg>
              Hargeisa. Somaliland building kaba weyne “ togdheer”
            </Link>
          </div>

          <div>
            <form method="POST" name="contact" ref={form} onSubmit={sendEmail} >
              <div>
                <label class="block text-base tracking-tight text-gray-600">
                  Name
                </label>
                <input
                  name="name"
                  type="text"
                  placeholder="Name"
                  required
                  class="mt-2 w-full border-none text-sm p-4 bg-gray-100 text-gray-700"
                />
              </div>
              <div class="mt-6">
                <label class="block text-base tracking-tight text-gray-600">
                  Email address
                </label>
                <input
                  name="email"
                  type="email"
                  placeholder="your@email.com"
                  required
                  class="mt-2 w-full border-none text-sm p-4 bg-gray-100 text-gray-700"
                />
              </div>
              <div class="mt-6">
                <label class="block text-base tracking-tight text-gray-600">
                  Message
                </label>
                <textarea
                  name="message"
                  placeholder="Your message"
                  required
                  class="mt-2 w-full border-none text-sm p-4 bg-gray-100 text-gray-700"
                ></textarea>
              </div>
              <button class="inline-block bg-yellow-800 text-white uppercase text-sm tracking-widest font-heading px-8 py-4 mt-6">
                Send message
              </button>
			  {send && (
          <div
            className=" text-green-700 bg-green-100 rounded-lg  "
            role="alert"
          >
            <span className="font-medium">Thanks for your Message </span> we wil
            contact you as soon as possible
          </div>
)}
            </form>
			
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;

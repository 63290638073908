import React from "react";


import Slider from "./Slide";
import vission from '../Assets/vission.jpg'
import mission from '../Assets/mission.jpg'
import objective from '../Assets/object.jpg'
import { Link } from "react-router-dom";

function About() {
  return (
    <div>
      <Slider />
      <section class="relative px-4 md:-mt-38 sm:px-8 lg:px-16 xl:px-40 2xl:px-64 lg:py-32 ">
        <div class="flex flex-col lg:flex-row lg:-mx-8">
          <div class="w-full lg:w-1/2 lg:px-8">
            <h2 class="text-3xl leading-tight font-bold  -mt-32 md:-mt-2">
              Welcome to Badbaado Private Ambalance
            </h2>
            <p class="text-lg mt-20 font-semibold">Our History</p>
            <p class="mt-6 leading-relaxed">
            Badbaado ambulance is the only private ambulance service in somaliland,we provide pre-hospital care services in line with the international standards.
            </p>
          </div>

          <div class="w-full lg:w-1/2 lg:px-8 mt-12 lg:mt-0">
            <h2 class="text-3xl leading-tight font-bold mt-4 ml-28">
              Core Values
            </h2>

            <div class="md:flex mt-6">
              <div>
                <div class="w-12 h-12 bg-blue-600 rounded-full"></div>
              </div>
              <div class="md:ml-8 mt-4 md:mt-0">
                <h4 class="text-xl font-bold leading-tight">
                  • Respect for People
                </h4>
                <p class="mt-2 leading-relaxed">
                  As an entity with employees over 15, we understand and respect
                  the difficult circumstances that we are working in. Every
                  employee shall be treated with quality and fairness resulting
                  in the development of a strong and friendly environment. This,
                  in turn, is reflected in our patient care services under
                  limited capacity, equipment and technology..
                </p>
              </div>
            </div>
            <div class="md:flex">
              <div>
                <div class="w-12 h-12 bg-blue-600 rounded-full"></div>
              </div>
              <div class="md:ml-8 mt-4 md:mt-0">
                <h4 class="text-xl font-bold leading-tight">Integrity</h4>
                <p class="mt-2 leading-relaxed">
                  We maintain transparency in all our processes and procedures
                  and carry out both internal and external audits to ensure full
                  compliance with governmental procedures and international
                  standards.
                </p>
              </div>
            </div>

            <div class="md:flex mt-8">
              <div>
                <div class="w-12 h-12 bg-blue-600 rounded-full"></div>
              </div>
              <div class="md:ml-8 mt-4 md:mt-0">
                <h4 class="text-xl font-bold leading-tight">Commitment</h4>
                <p class="mt-2 leading-relaxed">
                  {" "}
                  To save life and provide the highest quality pre- hospital
                  emergency service.
                </p>
              </div>
            </div>
          </div>
        </div>
        <section class="border-b py-8 ">
          <div class="container mx-auto flex flex-wrap pt-4 pb-12">
            <h1 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
              Badbaado Ambulance
            </h1>
 

      
            <div class="w-full mb-4 space-x-10">
              <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t "></div>
            </div>
            <div class="w-full md:w-1/3 p-6 flex flex-col shadow-xl  ">
              <div class="flex-1 bg-white  rounded-t rounded-b-none overflow-hidden shadow">
                <div class="w-full font-bold text-xl text-gray-800 px-6">
                  OUR VISION
                </div>
                <div className="">
                <img src={vission} alt=""className="shadow-md rounded-lg object-cover transition duration-300 hover:scale-90" /></div>
                <p class="text-gray-800 text-base px-6 mb-5 mt-10">
                  To be the leading ambulance care provider in Somaliland
                </p>
              </div>
              <div class="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                <div class="flex items-center justify-center">
       <Link to="/ContactUs">
                  <button class="btncontact  bg-yellow-500 lg:mx-0  gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out hover:text-black">
                    Contact Us
                  </button></Link>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink shadow-xl">
              <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div class="w-full font-bold text-xl text-gray-800 px-6">
                  OUR MISSION
                </div>
                <div className="">
                <img src={mission} alt=""className="shadow-2xl rounded-lg object-cover transition duration-300 hover:scale-90" /></div>
               
                <p class="text-gray-800 text-base px-6 mb-5 mt-10">
                  To provide top quality hospital services in order to
                  improve the outcome of affected people in Somaliland and
                  beyond.
                </p>
              </div>
              <div class="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-12">
                <div class="flex items-center justify-center">
                <Link to="/ContactUs">
                  <button class="btncontact  bg-yellow-500 lg:mx-0  gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out hover:text-black">
                    Contact Us
                  </button></Link>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink shadow-xl border">
              <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div class="w-full font-bold text-xl text-gray-800 px-6">
                  OBJECTIVES
                </div>
                <div className="">
                <img src={objective} alt=""className="shadow-md rounded-lg object-cover transition duration-300 hover:scale-90 h-48" /></div>
           
                <p class="text-gray-800 text-base px-6 mb-5 mt-20">
                  Providing ambulance services to the people of Somaliland,
                  ensuring excellence and continuous improvement in patient
                  outcomes by working collaboratively with partners and
                  stakeholders.
                </p>
              </div>
              <div class="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                <div class="flex items-center justify-center">
                <Link to="/ContactUs">
                  <button class="btncontact  bg-yellow-500 lg:mx-0  gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out hover:text-black">
                    Contact Us
                  </button></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section class="relative bg-blue-teal-gradient bg-red-500 px-4 sm:px-8 lg:px-16 xl:px-40 2xl:px-64 py-12 text-center md:text-left">
        <div class="md:flex md:items-center md:justify-center">
          <h2 class="text-xl font-bold text-white">
            Get in touch with us today! Call us on: 3033
          </h2>
          <Link
            to="/About"
            class="px-8 py-4 bg-white text-blue-600 rounded inline-block font-semibold md:ml-8 mt-4 md:mt-0"
          >
            Call Now
          </Link>
        </div>
      </section>
    </div>
  );
}

export default About;

import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQ() {
  return (
    <>

    <div className='mt-20 my-12 mx-12 space-y-6'>
  {/* <h1 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Badbaado FAQ
          </h1> */}
<Accordion className=' border-4'>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className='text-black font-bold flex text-3xl'> <h1 className='px-8 text-orange-500'>Q1</h1> <h1 className='font-semibold font-body'> How can i contact Badbaado Private Ambulances?</h1></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='font-extralight flex'><h1 className='font-bold md:px-12'>A: You can contact us by either emailing us at badbaadoambulance@gmail.com
or by calling us on (3033).</h1>
          </Typography>
        </AccordionDetails>
      </Accordion>
<Accordion className=' border-4'>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className='text-black font-bold flex text-3xl'> <h1 className='px-8 text-orange-500'>Q2</h1> <h1 className='font-semibold font-body'>Will the ambulance take me to my hospital of choice?</h1> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='font-extralight flex'><h1 className='font-bold sm:px-2  md:px-20'>A:Yes, customers can choose any hospitals or drop off locations. they will be given forms to fill out and on them, you can write where you wish to be dropped.</h1>
          </Typography>
        </AccordionDetails>
      </Accordion>
<Accordion className=' border-4'>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className='text-black font-bold flex text-3xl'> <h1 className='px-8 text-orange-500'>Q3</h1> <h1 className='font-semibold font-body'> What is emergency medical services or ambulance services?</h1> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='font-extralight flex'><h1 className='font-bold md:px-20 '>A:  are emergency services that provide urgent pre-hospital treatment and stabilization for serious   illness and injuries and transport to definitive care.</h1>
          </Typography>
        </AccordionDetails>
      </Accordion>

     
    </div></>
  )
}

export default FAQ
import pic1 from '../Assets/Gallery/pic1.jpg';
import pic2 from '../Assets/Gallery/pic2.jpg';
import pic3 from '../Assets/Gallery/pic3.jpg';
import pic4 from '../Assets/Gallery/pic4.jpg';
import pic5 from '../Assets/Gallery/pic5.jpg';
import pic6 from '../Assets/Gallery/pic6.jpg';
// import pic7 from '../Assets/Gallery/pic7.jpg';
// import pic8 from '../Assets/Gallery/pic8.jpg';
// import pic9 from '../Assets/Gallery/pic9.jpg';
// import pic10 from '../Assets/Gallery/pic10.jpg';
// import pic11 from '../Assets/Gallery/pic11.jpg';
// import pic12 from '../Assets/Gallery/pic12.jpg';
// import pic13 from '../Assets/Gallery/pic13.jpg';
// import pic14 from '../Assets/Gallery/pic14.jpg';
// import pic15 from '../Assets/Gallery/pic5.jpg';
// import pic16 from '../Assets/Gallery/pic16.png';

export default [
    {
url: pic1
    },
    {
url: pic2
    },
  
    {
url: pic3
    },
    {
url: pic4
    },
    {
url: pic5
    },
    {
url: pic6
    },
//     {
// url: pic7
//     },
//     {
// url: pic8
//     },
//     {
// url: pic9
//     },
//     {
// url: pic10
//     },
// {
// url: pic11
//     },
    

//     {
// url: pic12
//     },
//     {
// url: pic13
//     },
//     {
// url: pic14
//     },
//     {
// url: pic15
//     },
  
    
]
import React from "react";
import Third from "../Assets/six.jpg";
function GaleryHeader() {
  return (
    <div>


<section className=" bg-yellow-500  z-50 h-44 md:h-96">
    
          
          <img className="h-44 w-full md:h-96 absolute object-cover opacity-25 " src={Third} alt="" />
          <h2 className=" md:mx-96   md:top-36 relative   text-5xl font-sans text-white text-center  ">Image Gallery</h2>

          <h3 className="md:my-44 z-50 sm:mx-12 sm:w-1/ z-502  text-center  text-2xl font-serif text-white md:mx-80">Home   /  Gallery</h3>
        
        
      
    </section>
   
    </div>

  );
}

export default GaleryHeader;

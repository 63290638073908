import React from "react";
import badbaado1 from "../Assets/badbaado.png";
import badbaado2 from "../Assets/badbado2.png";
import { Link } from "react-router-dom";
import Slider from "./Slide";

function Services() {
  return (
    <div>
      <Slider />
      <section class="bg-white border-b py-8">
        <div class="container max-w-5xl mx-auto m-8">
          <h1 class="w-full -mt-12 text-5xl font-bold leading-tight text-center text-gray-800">
            Our Service
          </h1>
          <div class="w-full mb-4">
            <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-5/6 sm:w-1/2 p-6">
              <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
                Emergency Medical Services (EMS)
              </h3>
              <p class="text-gray-600 mb-8">
                includes provision of pre-hospital emergency medical services,
                oversight of EMS delivery, procurement, coordination of medical
                protocols, system planning, creation of deployment strategies,
                quality assurance and performance measurements,
                <br />
                <br />
                <Link to="/ContactUs">
                  <button className="px-4 py-3 border rounded-xl bg-amber-400 hover:bg-transparent hover:border-black mx-4">
                    Contactus
                  </button>
                </Link>
              </p>
            </div>
            <div class="w-full sm:w-1/2 p-6">
              <img src={badbaado1} alt="" />
            </div>
          </div>
          <div class="flex flex-wrap flex-col-reverse sm:flex-row">
            <div class="w-full sm:w-1/2 p-6 mt-6">
              <img src={badbaado2} alt="" />
            </div>
            <div class="w-full sm:w-1/2 p-6 mt-6">
              <div class="align-middle">
                <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
                  Our solutions are cost effective and comprise of the
                  following:
                </h3>
                <p class="text-gray-600 mb-8 mt-4">
                  Medical response ambulance to cater for accidents and other
                  emergencies<br></br>• Medical risk site assessments<br></br>•
                  Incident response exercises<br></br>• Clinical Services
                  <br></br>
                  <br />
                  <br />
                  for more information:
                  <Link to="/ContactUs">
                    <button className="px-4 py-3 border rounded-xl bg-amber-400 hover:bg-transparent hover:border-black mx-4">
                      Contactus
                    </button>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;

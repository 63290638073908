import React from "react";

function SliderContent({ activeIndex, sliderImage }) {
  return (
    <>
    <section className=" bg-yellow-500  z-50 h-96 md:h-screen">
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          
          <img className="h-96 w-full md:h-screen absolute object-cover opacity-25 " src={slide.urls} alt="" />
          <h2 className="z-50  mx-14 md:mx-96  w-full  text-5xl mt-8 font-serif text-white ">{slide.title}</h2>

          <h3 className="my-16 a z-50 sm:mx-12 sm:w-1/2 animate-bounce text-center top-64 md:text-2xl font-serif text-white md:mx-96">{slide.description}</h3>
          
          <div className="  z-50 md:mx-96 flex justify-center items-center space-x-6 ">
         
          <button class=" mx-auto lg:mx-0 hover:underline bg-white flex text-gray-800 font-bold rounded-full -my-10 py-6 px-12 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
              />
            </svg>
            <a href="tel:3033"class="ml-3 font-semibold">3033</a>
          </button>
   
          </div>
        </div>
      ))}
      
    </section>
   
    </>
  );
}

export default SliderContent;
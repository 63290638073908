import Header from './Components/Header';
import './index.css';
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import Home from './Components/Home';
import Footer from './Components/Footer';
import About from './Components/About';
import ContactUs from './Components/ContactUs';
import Services from './Components/Services';
import React from 'react'
import FAQ from './Components/FAQ';
import Gallery from './Components/Gallery';
import GaleryHeader from './Components/GalleryHeader';


function App() {
  return (
    <div className="App">
  <Router>
     <Header/>

   
   
     <Routes>
      <Route path='/' element={<Home  />} />
      <Route path='/About' element={<About  />} />
      <Route path='/ContactUs' element={<ContactUs  />} />
      <Route path='/Services' element={<Services />}/>
      <Route path='/FaQ' element={<FAQ />}/>
      <Route path='/Gallery' element={<Gallery />}/>
      <Route path='/test' element={<GaleryHeader />}/>
   
     </Routes>
     <Footer />
  <Routes >

    
  </Routes>
     
  
</Router>
 
    </div>
  );
}

export default App;

import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import badbaado1 from "../Assets/badbaado.png";
import badbaado2 from "../Assets/badbado2.png";
import callcenter from "../Assets/callcenter.svg";
import image1 from "../Assets/map.png";
import { Link } from "react-router-dom";
import Slider from "./Slide";

import Testimonial from "./Testimonial";

function Home() {
  
  return (
    <div>
    
      <Slider />
     
      <section class="bg-white border-b py-8">
        <div class="container max-w-6xl mx-auto m-8">
          <h1 class="w-full my-2 text-5xl -mt-60 md:-mt-2 font-bold leading-tight text-center text-gray-800">
            Our Service
          </h1>

          <div class="w-full mb-4">
            <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-5/6 sm:w-1/2 p-6">
              <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
                Emergency Medical Services (EMS)
              </h3>
              <p class="text-gray-600 mb-8">
                includes provision of pre-hospital emergency medical services,
                oversight of EMS delivery, procurement, coordination of medical
                protocols, system planning, creation of deployment strategies,
                quality assurance and performance measurements,
                <br />
                <br />
                <Link to="/ContactUs">
                  <button className="px-4 py-3 mt-20 border rounded-xl bg-amber-400 hover:bg-transparent hover:border-black mx-4">
                    Contact Us
                  </button>
                </Link>
              </p>
            </div>
            <div class="w-full sm:w-1/2 p-6">
              <img src={badbaado1} alt="" />
            </div>
          </div>
          <div class="flex flex-wrap flex-col-reverse sm:flex-row">
            <div class="w-full sm:w-1/2 p-6 mt-6">
              <img src={badbaado2} alt="" />
            </div>
            <div class="w-full sm:w-1/2 p-6 mt-6">
              <div class="align-middle">
                <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
                  Our solutions are cost effective and comprise of the
                  following:
                </h3>
                <p class="text-gray-600 mb-8 mt-4">
                  Medical response ambulance to cater for accidents and other
                  emergencies<br></br>• Medical risk site assessments<br></br>•
                  Incident response exercises<br></br>• Clinical Services
                  <br></br>
                  <br />
                  <br />
                  for more information:
                  <Link to="/ContactUs">
                    <button className="px-4 py-3 border rounded-xl bg-amber-400 hover:bg-transparent hover:border-black mx-4">
                      Contact Us
                    </button>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="container bg-yellow-600 rounded-lg mx-auto text-center py-6 mb-12">
        <div className="flex justify-center items-center">
          <h1 class=" my-2 text-5xl font-bold leading-tight text-center text-white">
            Call Now
          </h1>{" "}
          <img src={callcenter} alt="" className="w-12 h-12 mx-4" />
        </div>
        <div class="w-full mb-4">
          <div class="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        <div className=" ">
          <h3 class="my-4 text-3xl leading-tight text-center">
            Emergence Call 24/7
          </h3>
        </div>
        <div className='mt-20 my-12 mx-12 space-y-6 '>
  <h1 class="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
            Badbaado FAQ
          </h1>
<Accordion className=' border-4'>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className='text-black font-bold flex text-3xl'> <h1 className='px-8 text-orange-500'>Q1</h1> <h1 className='font-semibold font-body'> How can i contact Badbaado Private Ambulances?</h1></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='font-extralight flex'><h1 className='font-bold md:px-12'>A: You can contact us by either emailing us at badbaadoambulance@gmail.com
or by calling us on (3033)</h1>
          </Typography>
        </AccordionDetails>
      </Accordion>
<Accordion className=' border-4'>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className='text-black font-bold flex text-3xl'> <h1 className='px-8 text-orange-500'>Q2</h1> <h1 className='font-semibold font-body'>Will the ambulance take me to my hospital of choice??</h1> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='font-extralight flex'><h1 className='font-bold  md:px-20'>A: Yes, customers can choose any hospitals or drop off locations. they will be given forms to fill out and on them, you can write where you wish to be dropped.</h1>
          </Typography>
        </AccordionDetails>
      </Accordion>
<Accordion className=' border-4'>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className='text-black font-bold flex text-3xl'> <h1 className='px-8 text-orange-500'>Q3</h1> <h1 className='font-semibold font-body'> What are emergency medical services and ambulance services?</h1> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='font-extralight flex'><h1 className='font-bold md:px-20 '>A:  emergency medical services and ambulance services provide urgent pre-hospital treatment and stabilization for serious illness and injuries and transport to definitive care.</h1>
          </Typography>
        </AccordionDetails>
      </Accordion>

    
     
    </div>
        <div className=" flex justify-center items-center">
          <button class="mx-auto lg:mx-0 hover:underline bg-white flex text-gray-800 font-bold rounded-full my-6 py-6 px-12 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
              />
            </svg>
            <a href="tel:3033"class="ml-3 font-semibold">3033</a>
          </button>
        </div>
      </section>
      <Testimonial />
    </div>

  );
}

export default Home;
